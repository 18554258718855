.MuiDrawer-paper {
  max-width: 28%;
  width: 100% !important;
  height: 100% !important;
}

.MuiDrawer-root {
  height: 50vh !important;
  bottom: 0 !important;
  top: auto !important;
}

.MuiBackdrop-root {
  display: none !important;
}
.btn:focus {
  box-shadow: none;
}

/* Mobile Legend Styles */

.mobile-legend > .MuiPaper-root {
  opacity: 0.85 !important;
  background: rgba(0, 0, 0, 0.5) !important;
  color: #fff !important;
  align-items: center;
  font-size: 25px;
  padding-top: 50px;
}

.mobile-legend > .legend-section {
  border-bottom: 1px solid rgba(255, 255, 255, 0.75);
  margin-bottom: 8px;
}

.mobile-legend > .legend-section:not(:first-child) {
  margin-top: 12px;
}

.mobile-legend-entry {
  display: flex;
  line-height: 30px;
  margin-bottom: 4px;
}

.mobile-legend-item.square {
  margin-right: 6px;
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 4px;
}

@media (max-width: 992px) {
  .MuiDrawer-paper {
    max-width: 100%;
  }

  .standalone-map > .panel-header > .icons {
    display: none;
  }

  .legend {
    display: none;
  }
}

/* Mobile Search Styles */

.search-header {
  background-color: #717f8e;
  color: #fff;
  width: 100%;
}

.search-input {
  border: none;
}

.search-input:focus {
  box-shadow: none;
}

.search-icon {
  border: none;
  background-color: #fff;
}

.search-form {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition-property: border-color, box-shadow;
  transition-duration: 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out;
  transition-delay: 0s, 0s;
}

.search-form:focus-within {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  border-color: #80bdff;
}

.train-button {
  background-color: #edeff1;
  border-color: #edeff1;
  color: #495c6f;
}

.train-button:hover {
  background-color: #00aac3;
  border-color: #00aac3;
  color: #fff;
}

.train-button:not(:disabled):not(.disabled):active {
  background-color: #00aac3;
  border-color: #00aac3;
  color: #fff;
}

.train-button:focus {
  background-color: #1abc9c;
  border-color: #1abc9c;
  color: #fff;
}

/* Map Layer picker styles */
@media (max-width: 992px) {
  #overlay .overlay-container {
    flex-direction: column;
    align-items: center;
  }

  #overlay #layer-picker .all .btn {
    width: 50%;
    min-width: 0;
  }
}
#main-contant {
  overscroll-behavior: none;
}
#map-scrubber {
  overscroll-behavior: none;
}
/* #scrubber-region .playback-control .playback-timeline-wrapper {
  overscroll-behavior: none;
} */

#main-content .resizable-trains-list .standalone-active-trains {
  height: calc(100% + 49px);
}

#main-content .resizable-trains-list .standalone-active-trains .table-body {
  background-color: #fff;
}

.MuiDrawer-paper .standalone-active-trains {
  height: calc(100vh - 75px);
}

.mobile-train-drawer {
  .MuiDrawer-paperAnchorBottom {
    height: 50vh !important;
    z-index: 1300;
  }
}

@media (hover: none) {
  #root nav button.btn-outline-light {
    background-color: transparent;
    color: #f8f9fa;
  }

  #root nav button.btn-outline-light.active {
    background-color: #f8f9fa;
    color: #000;
  }

  #tooltip-top {
    opacity: 0;
    display: none;
  }
}

body #kvl-home section#scrubber-region {
  bottom: -48px;
  position: absolute;
}
@media (max-width: 992px) {
  body #kvl-home section#scrubber-region {
    bottom: 48px;
    position: fixed;
  }

  body #kvl-home #geo-map {
    height: calc(100% + 30px);
  }

  body #kvl-home .standalone-map .panel-header {
    display: none;
  }

  body #kvl-home .standalone-map .selected-train {
    top: 80px;
    bottom: unset;
  }
}

/* DARK THEME */
:root {
  --keolis-brown: #786e64;
  --keolis-brown-dark-1: #49433d;
  --drawer-theme-1-background-color: #63594f;
  --drawer-theme-1-background-color-light: #877c71;
  --drawer-theme-1-background-color-dark: #49433d;
  --drawer-theme-1-table-background-color-light: #beb5ac;
  --drawer-theme-1-table-background-color-light2: #e6dcd3;
  --drawer-theme-1-text-color: #ffffff;
  --drawer-theme-1-text-color-dark: #121e24;
}

body.drawer-theme-1 .standalone-active-train-drawer {
  .panel-header {
    background-color: var(--drawer-theme-1-background-color) !important;

    color: var(--drawer-theme-1-text-color) !important;
  }

  .panel-header h2 {
    color: var(--drawer-theme-1-text-color) !important;
  }

  .train-drawer-badge.badge.rounded-pill {
    background-color: var(--drawer-theme-1-background-color-light) !important;
  }

  .train-drawer-column {
    background-color: var(--drawer-theme-1-background-color-dark) !important;

    color: var(--drawer-theme-1-text-color) !important;
  }

  .train-drawer-table.table thead {
    background-color: var(--drawer-theme-1-background-color-light) !important;

    border: 0px;
  }

  .train-drawer-table.table thead th {
    border: 0px !important;

    color: var(--drawer-theme-1-text-color) !important;
  }

  .train-drawer-table.table tbody tr {
    background-color: var(--drawer-theme-1-table-background-color-light) !important;
  }

  .train-drawer-table.table tbody tr.odd-train-row {
    background-color: var(--drawer-theme-1-table-background-color-light2) !important;
  }

  .train-drawer-column svg {
    color: var(--drawer-theme-1-text-color) !important;
  }
}

/* DARK THEME WITH STATUS COLOR HEADER */
body.drawer-theme-1b .standalone-active-train-drawer {
  .panel-header {
    background-color: rgb(255 165 21) !important;

    color: #fff !important;
  }

  .panel-header h2 {
    color: #fff !important;
  }

  .train-drawer-badge.badge.rounded-pill {
    background-color: var(--drawer-theme-1-background-color-light) !important;
  }

  .train-drawer-column {
    background-color: var(--drawer-theme-1-background-color-dark) !important;

    color: var(--drawer-theme-1-text-color) !important;
  }

  .train-drawer-table.table thead {
    background-color: var(--drawer-theme-1-background-color-light) !important;

    border: 0px;
  }

  .train-drawer-table.table thead th {
    border: 0px !important;

    color: var(--drawer-theme-1-text-color) !important;
  }

  .train-drawer-column svg {
    color: var(--drawer-theme-1-text-color) !important;
  }
}

/* KEOLIS VIVID PURPLE THEME */
:root {
  --drawer-theme-2-background-color: #962d96;
  --drawer-theme-2-background-color-light: #ad5cad;
  --drawer-theme-2-background-color-dark: #6e0d6e;
  --drawer-theme-2-text-color: #f0c3f0;
  --drawer-theme-2-text-color-dark: #2f212f;
}

body.drawer-theme-2 .standalone-active-train-drawer {
  .panel-header {
    background-color: var(--drawer-theme-2-background-color) !important;

    color: var(--drawer-theme-2-text-color) !important;
  }

  .panel-header h2 {
    color: var(--drawer-theme-2-text-color) !important;
  }

  .train-drawer-badge.badge.rounded-pill {
    background-color: var(--drawer-theme-2-background-color-light) !important;
  }

  .train-drawer-column {
    background-color: var(--drawer-theme-2-background-color-dark) !important;

    color: var(--drawer-theme-2-text-color) !important;
  }

  .train-drawer-table.table thead {
    background-color: var(--drawer-theme-2-background-color-light) !important;

    border: 0px;
  }

  .train-drawer-table.table thead th {
    border: 0px !important;

    color: var(--drawer-theme-2-text-color) !important;
  }

  .train-drawer-column svg {
    color: var(--drawer-theme-2-text-color) !important;
  }
}

/* KEOLIS SEAFOAM THEME */

:root {
  --drawer-theme-3-background-color: #1abc9c;
  --drawer-theme-3-background-color-light: #25c1a2;
  --drawer-theme-3-background-color-dark: #059e7f;
  --drawer-theme-3-text-color: #dbf6f1;
  --drawer-theme-3-text-color-dark: #053229;
}

body.drawer-theme-3 .standalone-active-train-drawer {
  .panel-header {
    background-color: var(--drawer-theme-3-background-color) !important;

    color: var(--drawer-theme-3-text-color) !important;
  }

  .panel-header h2 {
    color: var(--drawer-theme-3-text-color) !important;
  }

  .train-drawer-badge.badge.rounded-pill {
    background-color: var(--drawer-theme-3-background-color-light) !important;
  }

  .train-drawer-column {
    background-color: var(--drawer-theme-3-background-color-dark) !important;

    color: var(--drawer-theme-3-text-color) !important;
  }

  .train-drawer-table.table thead {
    background-color: var(--drawer-theme-3-background-color-light) !important;

    border: 0px;
  }

  .train-drawer-table.table thead th {
    border: 0px !important;

    color: var(--drawer-theme-3-text-color) !important;
  }

  .train-drawer-column svg {
    color: var(--drawer-theme-3-text-color) !important;
  }
}
