.zoom-button-div {
  display: flex;
  justify-content: center;
  width: 22px;
  height: 22px;
  background-color: rgba(0, 60, 136, 0.5);
  border-radius: 2px;
  color: white;
  position: absolute;
  left: 10px;
  top: 100px;
  z-index: 9;
  cursor: pointer;
  text-align: center;
  padding: 4px;
}
.zoom-to-me-div {
  display: flex;
  justify-content: center;
  width: 22px;
  height: 22px;
  background-color: rgba(0, 60, 136, 0.5);
  border-radius: 2px;
  color: white;
  position: absolute;
  left: 10px;
  top: 60px;
  z-index: 9;
  cursor: pointer;
  text-align: center;
  padding: 4px;
}
.zoom-debug-button-div {
  display: flex;
  justify-content: center;
  background-color: rgba(0, 60, 136, 0.5);
  width: 22px;
  height: 22px;
  border-radius: 2px;
  color: white;
  position: absolute;
  left: 110px;
  top: 60px;
  z-index: 9;
  cursor: pointer;
  text-align: center;
  padding: 4px;
}
.zoom-debug-div {
  display: flex;
  justify-content: left;
  width: 500px;
  height: 22px;
  border-radius: 2px;
  color: black;
  position: absolute;
  left: 130px;
  top: 60px;
  z-index: 9;
  cursor: pointer;
  text-align: center;
  padding: 4px;
}
.standalone-active-trains .panel-header,
.standalone-map .panel-header,
.standalone-active-train-drawer .panel-header {
  padding: 10px 20px;
  height: 48px;
  z-index: 1;
}
.standalone-active-trains .panel-header.dk-blue,
.standalone-map .panel-header.dk-blue,
.standalone-active-train-drawer .panel-header.dk-blue {
  background: #822b6a;
}
.standalone-active-trains .panel-header.md-blue,
.standalone-map .panel-header.md-blue,
.standalone-active-train-drawer .panel-header.md-blue {
  background: #ae4d92;
}
.standalone-active-trains .panel-header h2,
.standalone-map .panel-header h2,
.standalone-active-train-drawer .panel-header h2 {
  color: #fff;
  font-size: 22px;
  margin: 3px 0 0;
  font-weight: 200;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.standalone-active-trains .panel-header .icons,
.standalone-map .panel-header .icons,
.standalone-active-train-drawer .panel-header .icons {
  float: right;
  text-align: right;
}
.standalone-active-trains .panel-header .icons .icon,
.standalone-map .panel-header .icons .icon,
.standalone-active-train-drawer .panel-header .icons .icon {
  display: inline-block;
  height: 28px;
  width: 28px;
  text-align: center;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  margin-left: 10px;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
}
.standalone-active-trains .panel-header .icons .icon .fa,
.standalone-map .panel-header .icons .icon .fa,
.standalone-active-train-drawer .panel-header .icons .icon .fa {
  line-height: 1;
}
.standalone-active-trains .panel-header .icons .icon:hover,
.standalone-map .panel-header .icons .icon:hover,
.standalone-active-train-drawer .panel-header .icons .icon:hover {
  color: #fff;
  border-color: #fff;
}
.standalone-active-trains .panel-header .icons .icon.active,
.standalone-map .panel-header .icons .icon.active,
.standalone-active-train-drawer .panel-header .icons .icon.active {
  background-color: #fff;
  color: #822b6a;
}
.standalone-active-trains table.train-drawer-table td,
.standalone-map table.train-drawer-table td,
.standalone-active-train-drawer table.train-drawer-table td {
  border-top: none !important;
}
.standalone-active-trains {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.standalone-active-trains .table-wrapper {
  height: calc(100% - 48px);
  border-right: 5px solid #D8D8D8;
  text-align: center;
}
.standalone-active-trains .table-row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 8px 15px;
  color: #495C6F;
  cursor: pointer;
  position: relative;
}
.standalone-active-trains .table-row > div {
  width: 14.285714%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.standalone-active-trains .table-row > div.alerts > *:not(:first-child) {
  margin-left: 10px;
}
.standalone-active-trains .table-row > div.alerts {
  width: 12%;
}
.standalone-active-trains .table-row > div.alerts-header {
  width: 12%;
}
.standalone-active-trains .table-row > div.speed {
  width: 8%;
}
.standalone-active-trains .table-row > div.next-stop {
  width: 24.84%;
}
.standalone-active-trains .table-row > div.track {
  width: 8%;
}
.standalone-active-trains .table-row > div .sa {
  padding: 0px 10px;
  border-radius: 10px;
  display: inline-block;
}
.standalone-active-trains .table-row:nth-child(2n+1) {
  background: #EDEFF1;
}
.standalone-active-trains .table-row.active {
  background: #00aac3;
  color: #fff;
}
.standalone-active-trains .table-head {
  font-weight: bold;
  color: #666;
  background: #fff;
}
.standalone-active-trains .table-head .table-row {
  background-color: #fff;
}
.standalone-active-trains .table-head .table-row > div:after {
  display: inline-block;
  margin-left: 5px;
  position: absolute;
  font: normal normal normal 14px/1 FontAwesome;
}
.standalone-active-trains .table-head .table-row > .sort-asc:after {
  content: '\f078';
  line-height: 24px;
}
.standalone-active-trains .table-head .table-row > .sort-desc:after {
  content: '\f077';
  line-height: 24px;
}
.standalone-active-trains .table-body {
  overflow-y: auto;
  overflow-y: overlay;
  height: calc(100% - 40px);
}
.standalone-map {
  flex: 1;
  width: 100%;
  height: 100%;
  position: relative;
}
.overlay-content {
  padding: 3px;
  background: rgba(255, 255, 255, 0.8);
  font-weight: bold;
  border-radius: 4px;
}
.overlay-content-dark {
  padding: 8px 10px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  background-color: #373737;
  border-radius: 6px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  min-height: 34px;
  font-size: 12px;
  line-height: 1.5;
}
.standalone-active-trains .alerts img {
  max-height: 25px;
}
.ol-zoom {
  display: none;
}
.ol-rotate {
  display: none;
}
.ol-attribution {
  display: none;
}
.train-drawer-column {
  background: #D0D8DF;
  height: calc(100vh - 48px);
  overflow-y: scroll;
}
.spinner-row {
  height: calc(100vh - 48px);
}
.odd-train-row {
  background: #F3F5F7;
}
.train-alert-card {
  background: #FBF3D6;
  color: #778594;
  font-weight: 800;
}
.train-alert-card-footer {
  font-style: italic;
  font-size: 14px;
  color: black;
  font-weight: 400;
}
.train-drawer-table {
  display: block;
  height: 30%;
  overflow-y: auto;
}
.train-drawer-table-head {
  position: sticky;
  top: 0;
  z-index: 1;
}
.train-drawer-table-body {
  display: block;
  height: 30%;
  overflow-y: auto;
  overflow-y: overlay;
}
.nonpublic-stop-name {
  color: #7f858a;
  font-style: 'italic' !important;
  font-weight: 500;
  text-align: 'right' !important;
}
.train-early {
  color: green;
}
.train-late {
  color: red;
}
.clock-icon {
  color: #4887d3;
}
.alert-icon {
  color: #c34f5d;
}
.train-icon {
  color: #da884a;
}
.sign-icon {
  color: #000000;
}
.bicycle-icon {
  color: #4887d3;
  margin-top: 4px;
}
.rail-coach-icon-l {
  height: 28px;
  width: 70px;
  background: #439CFA;
  text-align: center;
  clip-path: polygon(0% 0px, /* top left */ 20px 0%, /* top left */ calc(80%) 0%, /* top right */ 100% 10px, /* top right */ 100% 100%, /* bottom right */ calc(80%) 100%, /* bottom right */ 0% 100%, /* bottom left */ 0 calc(80%) /* bottom left */);
}
.rail-coach-icon-b {
  height: 34px;
  width: 70px;
  background: #00aac3;
  text-align: center;
  line-height: 2.1;
}
.rail-coach-icon-s {
  height: 28px;
  width: 70px;
  background: #00aac3;
  text-align: center;
}
.toilet-div-s {
  height: 21px;
  width: 70px;
  text-align: center;
  margin-top: 10px;
  color: #fff;
}
.toilet-div-b {
  height: 21px;
  width: 70px;
  text-align: center;
  margin-top: 10px;
  color: #fff;
}
.loading-spinner {
  color: #00aac3;
}
.consist-row {
  align-items: flex-end;
  white-space: nowrap;
}
.table-header {
  vertical-align: top !important;
  text-align: center;
}
.noWrap {
  white-space: nowrap;
}
.table-cell {
  text-align: center;
}
.track-row {
  display: flex;
  justify-content: space-between;
}
.scheduled-track {
  color: #7f858a;
  font-style: 'italic';
}
.actual-track-same {
  font-weight: 800;
  color: black;
}
.actual-track-different {
  font-weight: 800;
  color: red;
}
.timestamp-bar {
  display: inline-block;
  margin: 0 auto;
  position: relative;
  color: #fff;
  background-color: #00aac3;
}
#layer-picker .btn {
  font-weight: 400;
  background: #fff;
  color: #4A4A4A !important;
}
#layer-picker .btn.active {
  background: #00aac3;
  color: #fff !important;
}
#layer-picker .btn.active:after {
  content: "\f00c";
}
#layer-picker .btn:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  position: absolute;
  right: 17px;
  font-size: 17px;
  color: #fff !important;
}
#layer-picker .all {
  margin-bottom: 20px;
  text-align: center;
}
#layer-picker .all .btn {
  min-width: 350px;
  font-size: 14px;
  position: relative;
}
#overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 99;
  color: #fff;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
}
#overlay:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}
#overlay .overlay-inner {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  width: 100%;
  padding: 0 45px;
}
#overlay h1 {
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
  font-weight: 300;
}
#overlay .btn-lg {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.24);
  min-width: 94px;
}
#overlay .button-wrapper .btn-lg,
#overlay .certs .btn-lg {
  min-width: 0;
}
#overlay .Select-placeholder {
  padding-right: 25px;
}
#overlay .form-group .fa {
  color: #838383;
}
#overlay .keolis-close {
  position: fixed;
  top: 0;
  right: 0;
  margin: 0px 30px;
  font-size: 38px;
  color: #fff;
  cursor: pointer;
  z-index: 9999;
}
#overlay .overlay-wrapper {
  max-width: 1020px;
  width: auto;
}
#overlay .overlay-wrapper h1 {
  flex: 1 0 100%;
}
#overlay .overlay-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
#overlay .overlay-container.centered {
  justify-content: center;
}
#overlay .overlay-title {
  color: #fff;
  text-align: center;
  margin-top: 0;
  margin-bottom: 30px;
}
#overlay .btn-layout {
  justify-content: space-between;
  margin: auto;
  max-width: 770px;
}
#overlay .btn-layout .btn {
  width: 350px;
  margin-bottom: 20px;
  color: #fff;
  font-size: 14px;
  position: relative;
  max-width: 350px;
  width: 50%;
}
#overlay .btn-layout .btn-default {
  cursor: default;
}
#overlay.overlay-modal {
  background-color: #fff;
}
#overlay.overlay-modal .keolis-close {
  color: #838383;
}
.fade-appear,
.fade-enter {
  opacity: 0.01;
}
.fade-appear.fade-appear-active,
.fade-enter.fade-enter-active {
  transition: opacity 300ms ease-out;
  opacity: 1;
}
.fade-leave {
  opacity: 1;
}
.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-out;
}
#scrubber-region {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
}
#timestamp {
  color: #fff;
  background-color: #00aac3;
  text-align: center;
  height: 24px;
}
#timestamp .fa {
  top: 5px;
  left: -20px;
  position: absolute;
}
#timestamp .btn {
  padding: 2px 10px;
  margin: 0 0 0 10px;
}
#timestamp .displayed {
  display: inline-block;
  margin: 0 auto;
  position: relative;
}
#timestamp .displayed .btn {
  vertical-align: baseline;
}
#timestamp .form-group {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  margin-bottom: 0;
  margin: 0 auto;
}
#timestamp .form-group > input,
#timestamp .form-group > span {
  margin-right: 5px;
}
#timestamp .form-group .form-control {
  font-size: 12px;
  padding: 2px 4px;
  height: 20px;
  display: inline-block;
  width: auto;
}
#timestamp .form-group .form-control[type="number"] {
  width: 28px;
  text-align: center;
}
#timestamp .form-group .form-control[type="date"] {
  min-width: 80px;
}
#timestamp .form-group .Select {
  margin-right: 0;
  width: 43px;
}
#timestamp .form-group .Select .Select-control {
  width: 28px;
}
#timestamp .form-group .Select .Select-placeholder {
  padding-left: 0;
  line-height: 16px;
  height: 16px;
}
#timestamp .form-group .Select .Select-input,
#timestamp .form-group .Select .Select-input > input,
#timestamp .form-group .Select .Select-control {
  height: 16px;
  line-height: 16px;
  font-size: 12px;
}
#timestamp .form-group .Select .Select-menu-outer {
  font-size: 12px;
  width: 43px;
}
.see-thru {
  opacity: 0;
}
.is-iOS #timestamp .form-control[type="date"] {
  padding-top: 0;
}
#map-scrubber {
  background-color: rgba(120, 110, 100, 0.65);
  color: #fff;
  display: flex;
  align-items: middle;
}
#map-scrubber .playback-control {
  font-size: 24px;
  padding: 16px 14px;
  min-height: 62px;
  width: 45px;
  display: flex;
  align-items: center;
}
#map-scrubber .playback-control .playback-speed {
  color: #fff;
  font-size: 14px;
  line-height: 28px;
  min-width: 30px;
  display: inline-block;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 4px;
  cursor: pointer;
}
#map-scrubber .playback-control .speed-picker {
  position: absolute;
  bottom: 14px;
  border: 1px solid #fff;
  border-radius: 5px;
  background-color: #707F8E;
}
#map-scrubber .playback-control .speed-picker a {
  min-width: 30px;
  padding: 5px 0;
  display: block;
  color: #fff;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}
#map-scrubber .playback-control .speed-picker a:hover {
  background-color: #8B96A2;
}
#map-scrubber .playback-control .fa {
  cursor: pointer;
}
#map-scrubber .playback-timeline-wrapper {
  margin-right: 24px;
  flex: 1;
  position: relative;
  margin-left: 9px;
  display: flex;
  align-items: center;
}
#map-scrubber .playback-timeline-wrapper .playback-timeline {
  height: 8px;
  width: 100%;
  background-color: #786e64;
  position: relative;
  cursor: pointer;
  border-radius: 6px;
  margin-right: 12px;
}
#map-scrubber .playback-timeline-wrapper .playback-timeline .scrubber-dot-wrapper {
  position: absolute;
}
#map-scrubber .playback-timeline-wrapper .playback-timeline .scrubber-dot-wrapper .scrubber-dot {
  content: ' ';
  display: block;
  width: 18px;
  height: 18px;
  background: #00aac3;
  border-radius: 9px;
  margin-top: -5px;
  margin-left: -9px;
}
#map-scrubber .playback-timeline-wrapper .playback-timeline .scrubber-dot-wrapper .callout {
  position: absolute;
  top: -80px;
  width: 120px;
  left: -60px;
  cursor: default;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: #00aac3;
  border-radius: 8px;
}
#map-scrubber .playback-timeline-wrapper .playback-timeline .scrubber-dot-wrapper .callout:after {
  content: " ";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #00aac3;
  position: absolute;
  bottom: -10px;
  left: calc(50% - 10px);
}
#map-scrubber .playback-timeline-wrapper .playback-timeline .scrubber-dot-wrapper .callout .time-wrapper {
  margin: 0 auto;
  white-space: nowrap;
  text-align: center;
}
#map-scrubber .playback-timeline-wrapper .playback-timeline .scrubber-dot-wrapper .callout .fa {
  cursor: pointer;
  padding: 5px;
}
#map-scrubber .playback-timeline-wrapper .timeline-labels {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
}
#map-scrubber .playback-timeline-wrapper .timeline-labels .start {
  margin-right: 5px;
}
#map-scrubber .playback-timeline-wrapper .timeline-labels .now {
  margin-left: 5px;
}
#map-scrubber .playback-timeline-wrapper .timeline-labels .start,
#map-scrubber .playback-timeline-wrapper .timeline-labels .now {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tooltip-header {
  border-radius: 3px;
  color: #fff;
  padding-top: 2px;
  padding-bottom: 4px;
  margin-bottom: 5px;
}
.tooltip-header.revenue {
  background: #ae4d92;
}
.tooltip-header.non-revenue {
  background: #8B96A2;
}
.tooltip-header h5 {
  color: #fff;
  margin-bottom: 0px;
}
.train-login-input {
  padding-right: 0px !important;
}
.very-low-padding {
  padding: 0.25rem !important;
}
.train-card {
  position: relative;
  font-size: 12px;
  width: 270px;
}
.train-card.untagged {
  background: grey;
  color: #fff;
}
.train-card.untagged .cancel-btn {
  color: #fff;
}
.train-card.tagged .btn-outline-light {
  color: #ae4d92 !important;
  border-color: #ae4d92 !important;
}
.train-card.tagged .btn-outline-light:hover {
  color: #fff !important;
  background-color: #ae4d92 !important;
}
.train-card.tagged .cancel-btn {
  color: #ae4d92;
}
.train-card .train-login-input input.train-login-input {
  height: 100%;
}
.tooltip-subheader {
  background: #439CFA;
}
.late-text {
  color: #FF7216;
}
.early-text {
  color: #13A89E;
}
.train-tooltip {
  z-index: 50;
}
.selected-train {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100px;
  text-align: center;
}
.selected-train .selected-train-inner {
  display: inline-block;
  background-color: #00aac3;
  border-radius: 8px;
  color: #fff;
  padding: 2px 15px;
}
.selected-train .selected-train-inner .selected-train-close {
  display: inline-block;
  height: 100%;
  width: 20px;
  text-align: right;
  cursor: pointer;
}
#geo-map {
  height: 100%;
  position: relative;
}
#geo-map .map {
  height: 100%;
}
#geo-map .legend {
  position: absolute;
  top: 0.5em;
  right: 15px;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
}
#geo-map .legend.visible {
  opacity: 1;
  pointer-events: auto;
}
#geo-map .legend .legend-detail {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 6px;
  color: #fff;
  width: 150px;
}
#geo-map .legend .legend-detail:not(:last-child) {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
#geo-map .legend .legend-detail:not(:first-child) {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
#geo-map .legend .legend-key:hover .legend-detail {
  opacity: 1;
  z-index: 1;
}
#geo-map .legend .legend-section {
  border-bottom: 1px solid rgba(255, 255, 255, 0.75);
  margin-bottom: 8px;
}
#geo-map .legend .legend-section:not(:first-child) {
  margin-top: 12px;
}
#geo-map .legend .legend-entry {
  display: flex;
  line-height: 16px;
  margin-bottom: 4px;
}
#geo-map .legend .legend-entry .legend-item {
  margin-right: 6px;
}
#geo-map .legend .legend-entry .legend-item.square {
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 4px;
}
#system-map {
  padding-top: 48px;
  height: 100%;
  width: 100%;
  background-color: #8e44ad;
  position: relative;
}
#system-map #legend-wrapper {
  position: absolute;
  bottom: 125px;
  left: 15px;
  right: 15px;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
}
#system-map #legend-wrapper.visible {
  opacity: 1;
  pointer-events: auto;
}
#system-map .station-guide {
  display: none;
}
#system-map #legend {
  max-width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 175px;
  flex-wrap: wrap;
  padding: 15px;
  background-color: rgba(230, 230, 230, 0.75);
  border-radius: 4px;
}
#system-map #legend .fa {
  margin-right: 10px;
}
#system-map #legend .on-time .fa {
  color: #13A89E;
}
#system-map #legend .late .fa {
  color: #FFDE16;
}
#system-map #legend .pretty-late .fa {
  color: #FFA516;
}
#system-map #legend .very-late .fa {
  color: #FF7216;
}
#system-map #legend .super-late .fa {
  color: #ED1C24;
}
#system-map #legend .ultra-late .fa {
  color: #000;
}
#system-map #legend .inbound .fa {
  color: #822b6a;
}
#system-map #legend .outbound .fa {
  color: #FFFFFF;
}
#system-map #map-svg {
  position: absolute;
  padding: 45px 30px;
  max-width: 100%;
  max-height: calc(100% - 160px);
}
#system-map #map-svg text {
  font-size: 14px;
  fill: #fff;
}
#system-map .overlay {
  fill: #9B59B6;
}
#system-map .railline {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 4px;
}
#system-map .highlight {
  stroke: yellow;
  stroke-width: 6px;
  z-index: 100;
}
#system-map .station,
#system-map .station2 {
  stroke: none;
  fill: #000;
}
#system-map .station2 {
  fill: #000;
  stroke: #fff;
  stroke-width: 1;
}
#system-map .terminus {
  stroke: #fff;
  stroke-width: 4px;
}
#system-map .train {
  stroke-width: 3;
}
.xs-panel #system-map #map-svg text,
.sm-panel #system-map #map-svg text {
  font-size: 24px;
}
.xs-panel #system-map #map-svg .railline,
.sm-panel #system-map #map-svg .railline {
  stroke-width: 6px;
}
.xs-panel #system-map #map-svg .station2.terminus,
.sm-panel #system-map #map-svg .station2.terminus {
  stroke-width: 5px;
}
.xs-panel #system-map #map-svg .train,
.sm-panel #system-map #map-svg .train {
  stroke-width: 5px;
}
.xs-panel #system-map .station-guide,
.sm-panel #system-map .station-guide {
  display: block;
  margin-bottom: 15px;
}
.lg-panel #system-map #map-svg {
  max-height: calc(100% - 200px);
}
.lg-panel #system-map #legend,
.xl-panel #system-map #legend {
  flex-direction: row;
  width: auto;
  height: auto;
  justify-content: space-around;
}
.xxl-panel #legend-wrapper {
  width: 300px;
  top: 60px;
}
.train-tooltip .rc-tooltip-inner {
  font-weight: bold;
}
.train-tooltip .rc-tooltip-inner em {
  font-weight: normal;
}
.train-tooltip.outbound .rc-tooltip-inner {
  background-color: #fff;
  color: #3B3B3B;
}
.train-tooltip.inbound .rc-tooltip-inner {
  background-color: #822b6a;
  color: #fff;
}
.train-tooltip .rc-tooltip-arrow {
  transform: translateX(5px);
}
.train-tooltip.on-time .rc-tooltip-inner {
  border-color: #13A89E;
}
.train-tooltip.on-time .rc-tooltip-arrow {
  border-top-color: #13A89E;
}
.train-tooltip.late .rc-tooltip-inner {
  border-color: #FFDE16;
}
.train-tooltip.late .rc-tooltip-arrow {
  border-top-color: #FFDE16;
}
.train-tooltip.pretty-late .rc-tooltip-inner {
  border-color: #FFA516;
}
.train-tooltip.pretty-late .rc-tooltip-arrow {
  border-top-color: #FFA516;
}
.train-tooltip.very-late .rc-tooltip-inner {
  border-color: #FF7216;
}
.train-tooltip.very-late .rc-tooltip-arrow {
  border-top-color: #FF7216;
}
.train-tooltip.super-late .rc-tooltip-inner {
  border-color: #ED1C24;
}
.train-tooltip.super-late .rc-tooltip-arrow {
  border-top-color: #ED1C24;
}
.train-tooltip.ultra-late .rc-tooltip-inner {
  border-color: #000;
}
.train-tooltip.ultra-late .rc-tooltip-arrow {
  border-top-color: #000;
}
.train-tooltip .rc-tooltip-inner {
  border-width: 4px;
  border-style: solid;
}
.line-tooltip .rc-tooltip-content {
  margin-left: -16px;
}
.line-tooltip .rc-tooltip-arrow {
  margin-left: -8px;
}
.line-tooltip.char2 .rc-tooltip-content {
  margin-left: -26px;
}
.line-tooltip.char2 .rc-tooltip-arrow {
  margin-left: -13px;
}
.is-IE .xs-panel #system-map #map-svg,
.is-IE .sm-panel #system-map #map-svg {
  padding-top: 0;
}
#option-bar {
  min-height: 70px;
  background: #786e64;
}
.option-tile {
  border: 3px solid white;
}
.option-card {
  height: 5vh;
}
