:root {
  --gray: #dee2e6;
}

button::after {
  display: none !important;
}

.menu-button button {
  color: white !important;
  background-color: white !important;
}

.kvl-card {
  border: none !important;
  border-bottom: 1px solid var(--gray) !important;
  border-radius: 0 !important;
  width: 100%;
  height: 100px;
  overflow: hidden;
}

.kvl-detail-card {
  border-bottom: 1px solid var(--gray);
}

.kvl-list-container {
  max-height: 80%;
  overflow-y: scroll;
}

.kvl-card-body {
  padding-top: 0;
  padding-bottom: 0;
  padding: var(--bs-card-spacer-y) 2px !important;
}

.kvl-card-header {
  font-style: bold;
  font-weight: 800;
}

.kvl-card-text {
  color: #a4a2a5;
  margin-bottom: 0;
}

.all-page-close-button {
  height: 39px;
  max-height: 39px;
  margin-right: 10px;
}
